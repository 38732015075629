import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as Icon from 'react-feather'
import Layout from "../components/layout"
import Seo from "../components/seo"

const readGuides = [
  {
    text: "Log Reading & Add Books",
    url: "/guides/goread/log-reading-adding-books.pdf",
    fileType: "pdf"
  },
  {
    text: "Activity Feeds",
    url: "/guides/goread/class-pupil-activity-feeds.pdf",
    fileType: "pdf"
  },
  {
    text: "Class Data",
    url: "/guides/goread/class-data.pdf",
    fileType: "pdf"
  },
  {
    text: "Group Books",
    url: "/guides/goread/group-books.pdf",
    fileType: "pdf"
  },
  {
    text: "Adding Reading Bands",
    url: "/guides/goread/reading-bands.pdf",
    fileType: "pdf"
  },
  {
    text: "Getting Started With Parents",
    url: "/guides/goread/getting-started-with-parents.pdf",
    fileType: "pdf"
  },
  {
    text: "Hints & Tips",
    url: "/guides/goread/hints-tips.pdf",
    fileType: "pdf"
  },
  {
    text: "Pupil Guide",
    url: "/guides/goread/pupils-guide.pdf",
    fileType: "pdf"
  }
]
const readParentsGuides = [
  {
    text: "Parent Guide (Mobile App)",
    url: "/guides/goread/parent-quick-start-guide.pdf",
    fileType: "pdf"
  },
  {
    text: "Parent Guide (Web App)",
    url: "/guides/goread/parent-guide-web.pdf",
    fileType: "pdf"
  },
  {
    text: "Posters",
    url: "/resources/parent-app-posters-v2.pdf",
    fileType: "pdf"
  },
  {
    text: "Parents Intro Slides",
    url: "/resources/parents-boomreader-Introduction.pptx",
    fileType: "pptx"
  },
  {
    text: "Editable parent letter",
    url: "/resources/editable-parent-letter.docx",
    type: "docx"
  }
]

const writeGuides = [
  {
    text: "School Administrator Guide",
    url: "/guides/gowrite/administrator-guide.pdf",
    fileType: "pdf"
  },
  {
    text: "School Staff Guide",
    url: "/guides/gowrite/staff-guide.pdf",
    fileType: "pdf"
  },
  {
    text: "Pupil Guide",
    url: "/guides/gowrite/pupil-guide.pdf",
    fileType: "pdf"
  },
]

const resources = [
  {
    text: "Reading Hero Certificate",
    url: "/resources/reading-hero-certificate.pdf",
    fileType: "pdf"
  },

  {
    text: "Boomreader Pupil Intro Slides",
    url: "/resources/boomreader-pupil-intro-slides.pptx",
    fileType: "pptx"
  }
]

const summerResources = [
  {
    text: "Class Presenter (PowerPoint)",
    url: "/resources/summer-readathon/class-presentation.pptx",
    fileType: "pptx"
  },
  {
    text: "Class Presenter (PDF)",
    url: "/resources/summer-readathon/class-presentation.pdf",
    fileType: "pdf"
  },
  {
    text: "Posters",
    url: "/resources/summer-readathon/posters.pdf",
    fileType: "pdf"
  },
  {
    text: "Parent Flyer (Editable)",
    url: "/resources/summer-readathon/parent-flyer.docx",
    fileType: "docx"
  },
  {
    text: "Parent Flyer",
    url: "/resources/summer-readathon/parent-flyer.pdf",
    fileType: "pdf"
  },
]

const GuidesPage = () => (
  <Layout>
    <Seo title="Guides" />
    <div className="container">

      <section className="mb">
        <div className="homeIntro mb">
          <h1>Guides & Resources</h1>
          <div className="homeIntro_help">
            <h2 className="txt-default">Need Help?</h2>
            <div className="homeIntro_links">
              <a className="link" href="mailto:support@boomhub.app">Contact Us</a>
            </div>
          </div>
        </div>
        
        <div className="grid_two">
          <div>
            <h2>BoomReader</h2>
            <ul className="docList">
              <li className="docList_item">
                <a href="/guides/goread/administrator-guide.pdf" className="docList_link" target="_blank">
                  <span className="docList_desc">
                    <Icon.Download />
                    School Administrator Guide
                  </span>
                  <span className="docList_meta">PDF</span>
                </a>
              </li>
            </ul>
            <h3 className="txt-large">Staff Guides</h3>
            <ul className="docList mb">
              {readGuides.map(g => (
                <React.Fragment key={g.url}>
                  <li className="docList_item">
                    <a href={g.url} className="docList_link" target="_blank">
                      <span className="docList_desc">
                        <Icon.Download />
                        {g.text}
                      </span>
                      <span className="docList_meta">{g.fileType}</span>
                    </a>
                  </li>
                </React.Fragment>
              ))}
            </ul>
            {/* <h3 className="txt-large">Parent Guides</h3>
            <p className="">Please note that parents can download these guides direct from the GoRead website.</p>
            <ul className="docList">
              {readParentsGuides.map(g => (
                <React.Fragment key={g.url}>
                  <li className="docList_item">
                    <a href={g.url} className="docList_link" target="_blank">
                      <span className="docList_desc">
                        <Icon.Download />
                        {g.text}
                      </span>
                      <span className="docList_meta">{g.fileType}</span>
                    </a>
                  </li>
                </React.Fragment>
              ))}
            </ul> */}
          </div>
          <div>
            <h2>BoomWriter</h2>
            <ul className="docList">
              {writeGuides.map(g => (
                <React.Fragment key={g.url}>
                  <li className="docList_item">
                    <a href={g.url} className="docList_link" target="_blank">
                      <span className="docList_desc">
                        <Icon.Download />
                        {g.text}
                      </span>
                      <span className="docList_meta">{g.fileType}</span>
                    </a>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="resourcesPanel mb" id="ParentApp">
        <h2>BoomReader Parent App Guides & Resources</h2>
        <ul className="docList grid_two">
          {readParentsGuides.map(g => (
            <li className="docList_item">
              <a href={g.url} className="docList_link" target="_blank">
                <span className="docList_desc">
                  <Icon.Download />
                  {g.text}
                </span>
                <span className="docList_meta">{g.fileType}</span>
              </a>
            </li>
          ))}
        </ul>
      </section>

      <section className="resourcesPanel">
        <h2>Other Resources & Downloads</h2>
        <div className="">
          <ul className="docList grid_two">
            {resources.map(g => (
              <React.Fragment key={g.url}>
                <li className="docList_item">
                  <a href={g.url} className="docList_link" target="_blank">
                    <span className="docList_desc">
                      <Icon.Download />
                      {g.text}
                    </span>
                    <span className="docList_meta">{g.fileType}</span>
                  </a>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </section>

      {/* <section id="SummerReadathon" className="resourcesPanel summer">
        <div className="summer_header">
          <h2>Summer GoReadation 2022</h2>
          <StaticImage src="../images/summer-logo.png" alt="The Summer GoReadathon" placeholder="blurred" loading="eager" width={100} className="summer_logo" />
        </div>
        <div className="">
          <ul className="docList grid_two">
            {summerResources.map(g => (
              <React.Fragment key={g.url}>
                <li className="docList_item">
                  <a href={g.url} className="docList_link" target="_blank">
                    <span className="docList_desc">
                      <Icon.Download />
                      {g.text}
                    </span>
                    <span className="docList_meta">{g.fileType}</span>
                  </a>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </section> */}

    </div>
  </Layout>
)

export default GuidesPage
